<template>
  <div
    class="flex items-center justify-center bg-white hover:bg-black/5 rounded-[8px] cursor-pointer h-[40px] px-[40px]"
  >
    <p
      class="font-roboto text-[#2e2e2e] text-[13px] leading-[15px] tracking-[.02em]"
    >
      Добавить метку
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
