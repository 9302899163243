export default {
  width: 16,
  height: 16,
  viewBox: '0 0 16 16',
  path: [
    'M15.8131 4.18353C15.8723 4.24157 15.9193 4.3105 15.9514 4.3864C15.9835 4.4623 16 4.54366 16 4.62584C16 4.70801 15.9835 4.78938 15.9514 4.86527C15.9193 4.94117 15.8723 5.01011 15.8131 5.06814L11.996 8.81646C11.9369 8.87464 11.8667 8.9208 11.7894 8.95229C11.7121 8.98379 11.6292 9 11.5456 9C11.4619 9 11.379 8.98379 11.3017 8.95229C11.2244 8.9208 11.1542 8.87464 11.0951 8.81646L9.18657 6.9423C9.06711 6.825 9 6.6659 9 6.5C9 6.3341 9.06711 6.175 9.18657 6.0577C9.30603 5.94039 9.46805 5.87449 9.63699 5.87449C9.80593 5.87449 9.96795 5.94039 10.0874 6.0577L11.5456 7.49081L14.9123 4.18353C14.9714 4.12536 15.0416 4.0792 15.1188 4.0477C15.1961 4.01621 15.279 4 15.3627 4C15.4464 4 15.5292 4.01621 15.6065 4.0477C15.6838 4.0792 15.754 4.12536 15.8131 4.18353Z',
    'M15.8131 1.18353C15.8723 1.24157 15.9193 1.3105 15.9514 1.3864C15.9835 1.4623 16 1.54366 16 1.62584C16 1.70801 15.9835 1.78938 15.9514 1.86527C15.9193 1.94117 15.8723 2.01011 15.8131 2.06814L11.996 5.81646C11.9369 5.87464 11.8667 5.9208 11.7894 5.95229C11.7121 5.98379 11.6292 6 11.5456 6C11.4619 6 11.379 5.98379 11.3017 5.95229C11.2244 5.9208 11.1542 5.87464 11.0951 5.81646L9.18657 3.9423C9.06711 3.825 9 3.6659 9 3.5C9 3.3341 9.06711 3.175 9.18657 3.0577C9.30603 2.94039 9.46805 2.87449 9.63699 2.87449C9.80593 2.87449 9.96795 2.94039 10.0874 3.0577L11.5456 4.49081L14.9123 1.18353C14.9714 1.12536 15.0416 1.0792 15.1188 1.0477C15.1961 1.01621 15.279 1 15.3627 1C15.4464 1 15.5292 1.01621 15.6065 1.0477C15.6838 1.0792 15.754 1.12536 15.8131 1.18353Z',
    'M0.833333 14C0.833333 14 0 14 0 13.1667C0 12.3333 0.833333 9.83333 5 9.83333C9.16667 9.83333 10 12.3333 10 13.1667C10 14 9.16667 14 9.16667 14H0.833333ZM5 9C5.66304 9 6.29893 8.73661 6.76777 8.26777C7.23661 7.79893 7.5 7.16304 7.5 6.5C7.5 5.83696 7.23661 5.20107 6.76777 4.73223C6.29893 4.26339 5.66304 4 5 4C4.33696 4 3.70107 4.26339 3.23223 4.73223C2.76339 5.20107 2.5 5.83696 2.5 6.5C2.5 7.16304 2.76339 7.79893 3.23223 8.26777C3.70107 8.73661 4.33696 9 5 9Z'
  ]
}
