export default {
  width: 18,
  height: 17,
  viewBox: '0 0 18 17',
  path: [
    'M0 1.7C0 0.761116 0.746192 0 1.66667 0H16.3333C17.2538 0 18 0.761116 18 1.7C18 2.63888 17.2538 3.4 16.3333 3.4H1.66667C0.746192 3.4 0 2.63888 0 1.7Z',
    'M0 8.5C0 7.56112 0.746192 6.8 1.66667 6.8H16.3333C17.2538 6.8 18 7.56112 18 8.5C18 9.43888 17.2538 10.2 16.3333 10.2H1.66667C0.746192 10.2 0 9.43888 0 8.5Z',
    'M0 15.3C0 14.3611 0.746192 13.6 1.66667 13.6H16.3333C17.2538 13.6 18 14.3611 18 15.3C18 16.2389 17.2538 17 16.3333 17H1.66667C0.746192 17 0 16.2389 0 15.3Z'
  ]
}
