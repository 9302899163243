<script>
export default {
  props: {
    quoteMessage: {
      type: Object,
      default: () => {}
    },
    employee: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['onClearQuote']
}
</script>

<template>
  <div
    class="flex items-start space-x-[10px] w-full justify-between"
    :class="{ 'bg-white': showCloseIcon }"
  >
    <div class="grow w-[calc(100%-20px)]">
      <div
        class="mx-[4px]"
      >
        <p class="text-[11px] leading-[16px] overflow-hidden text-black text-ellipsis whitespace-nowrap">
          {{ employee?.name }}
        </p>
        <p class="text-[12px] leading-[16px] overflow-hidden text-[#9a9fa6] text-ellipsis whitespace-nowrap">
          {{ quoteMessage.msg ?? quoteMessage.file_name }}
        </p>
      </div>
    </div>
    <svg
      width="13"
      class="cursor-pointer"
      height="13"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="$emit('onClearQuote')"
    >
      <path
        d="M4.94387 4.00273L7.81053 1.14273C7.93607 1.01719 8.00659 0.846929 8.00659 0.669395C8.00659 0.49186 7.93607 0.321597 7.81053 0.196061C7.685 0.0705253 7.51473 0 7.3372 0C7.15967 0 6.9894 0.0705253 6.86387 0.196061L4.00387 3.06273L1.14387 0.196061C1.01833 0.0705253 0.848067 -1.32273e-09 0.670532 0C0.492998 1.32274e-09 0.322735 0.0705253 0.197199 0.196061C0.0716631 0.321597 0.00113773 0.49186 0.00113773 0.669395C0.00113773 0.846929 0.0716631 1.01719 0.197199 1.14273L3.06387 4.00273L0.197199 6.86273C0.134713 6.92471 0.0851172 6.99844 0.0512714 7.07968C0.0174257 7.16092 0 7.24805 0 7.33606C0 7.42407 0.0174257 7.51121 0.0512714 7.59245C0.0851172 7.67369 0.134713 7.74742 0.197199 7.8094C0.259174 7.87188 0.332908 7.92148 0.414148 7.95532C0.495387 7.98917 0.582524 8.00659 0.670532 8.00659C0.75854 8.00659 0.845677 7.98917 0.926917 7.95532C1.00816 7.92148 1.08189 7.87188 1.14387 7.8094L4.00387 4.94273L6.86387 7.8094C6.92584 7.87188 6.99958 7.92148 7.08082 7.95532C7.16205 7.98917 7.24919 8.00659 7.3372 8.00659C7.42521 8.00659 7.51234 7.98917 7.59358 7.95532C7.67482 7.92148 7.74856 7.87188 7.81053 7.8094C7.87302 7.74742 7.92262 7.67369 7.95646 7.59245C7.99031 7.51121 8.00773 7.42407 8.00773 7.33606C8.00773 7.24805 7.99031 7.16092 7.95646 7.07968C7.92262 6.99844 7.87302 6.92471 7.81053 6.86273L4.94387 4.00273Z"
        fill="#7E7E80"
      />
    </svg>
  </div>
</template>
