<template>
  <div
    class="flex items-center bg-[#F4F5F7] rounded-[6px] text-[#575758] text-[12px] px-[8px] py-[5px] font-[500] group"
    :class="{'cursor-pointer': canEdit}"
  >
    <svg
      width="17"
      height="12"
      class="mr-[7px]"
      :class="{'group-hover:hidden': canEdit && budget}"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.57405 1.55572C2.37759 1.55572 2.18918 1.63376 2.05027 1.77268C1.91135 1.9116 1.83331 2.10001 1.83331 2.29646V9.70387C1.83331 9.90033 1.91135 10.0887 2.05027 10.2277C2.18918 10.3666 2.37759 10.4446 2.57405 10.4446H14.4259C14.6224 10.4446 14.8108 10.3666 14.9497 10.2277C15.0886 10.0887 15.1666 9.90033 15.1666 9.70387V2.29646C15.1666 2.10001 15.0886 1.9116 14.9497 1.77268C14.8108 1.63376 14.6224 1.55572 14.4259 1.55572H2.57405ZM1.0027 0.725114C1.41945 0.308366 1.98468 0.0742402 2.57405 0.0742402H14.4259C15.0153 0.0742402 15.5805 0.308367 15.9973 0.725114C16.414 1.14186 16.6481 1.70709 16.6481 2.29646V9.70387C16.6481 10.2932 16.414 10.8585 15.9973 11.2752C15.5805 11.692 15.0153 11.9261 14.4259 11.9261H2.57405C1.98468 11.9261 1.41945 11.692 1.0027 11.2752C0.585955 10.8585 0.351828 10.2932 0.351828 9.70387V2.29646C0.351828 1.70709 0.585954 1.14186 1.0027 0.725114Z"
        fill="black"
        fill-opacity="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49998 4.51869C7.68178 4.51869 7.0185 5.18197 7.0185 6.00017C7.0185 6.81837 7.68178 7.48165 8.49998 7.48165C9.31818 7.48165 9.98146 6.81837 9.98146 6.00017C9.98146 5.18197 9.31818 4.51869 8.49998 4.51869ZM5.53701 6.00017C5.53701 4.36377 6.86358 3.0372 8.49998 3.0372C10.1364 3.0372 11.4629 4.36377 11.4629 6.00017C11.4629 7.63657 10.1364 8.96313 8.49998 8.96313C6.86358 8.96313 5.53701 7.63657 5.53701 6.00017Z"
        fill="black"
        fill-opacity="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.339 0.130626C4.6158 0.245279 4.79627 0.515379 4.79627 0.814981C4.79627 1.79726 4.40606 2.73932 3.71148 3.4339C3.0169 4.12847 2.07485 4.51868 1.09257 4.51868C0.792967 4.51868 0.522866 4.33821 0.408214 4.06141C0.293561 3.78462 0.356936 3.46601 0.568786 3.25416L3.53175 0.291198C3.7436 0.079348 4.06221 0.0159733 4.339 0.130626ZM15.9074 8.96313C15.318 8.96313 14.7528 9.19726 14.336 9.614C13.9193 10.0308 13.6852 10.596 13.6852 11.1854C13.6852 11.5945 13.3535 11.9261 12.9444 11.9261C12.5353 11.9261 12.2037 11.5945 12.2037 11.1854C12.2037 10.2031 12.5939 9.26102 13.2885 8.56644C13.9831 7.87186 14.9251 7.48165 15.9074 7.48165C16.3165 7.48165 16.6481 7.81329 16.6481 8.22239C16.6481 8.63149 16.3165 8.96313 15.9074 8.96313Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
    <div
      class="flex items-center justify-center p-[4.5px] mr-[4px] hidden"
      :class="{ 'group-hover:block': canEdit && budget }"
      @click.stop="$emit('OnWipeBudget', 0)"
    >
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class=""
      >
        <path
          d="M6.17983 5.00341L9.76317 1.42841C9.92009 1.27149 10.0082 1.05866 10.0082 0.836743C10.0082 0.614825 9.92009 0.401996 9.76317 0.245076C9.60625 0.0881567 9.39342 0 9.1715 0C8.94958 0 8.73675 0.0881567 8.57983 0.245076L5.00483 3.82841L1.42983 0.245076C1.27291 0.0881567 1.06008 -1.65342e-09 0.838165 0C0.616247 1.65342e-09 0.403418 0.0881567 0.246499 0.245076C0.0895788 0.401996 0.00142217 0.614825 0.00142217 0.836743C0.00142217 1.05866 0.0895788 1.27149 0.246499 1.42841L3.82983 5.00341L0.246499 8.57841C0.168392 8.65588 0.106397 8.74805 0.0640893 8.8496C0.0217821 8.95115 0 9.06007 0 9.17008C0 9.28009 0.0217821 9.38901 0.0640893 9.49056C0.106397 9.59211 0.168392 9.68427 0.246499 9.76174C0.323968 9.83985 0.416135 9.90185 0.517685 9.94415C0.619234 9.98646 0.728156 10.0082 0.838165 10.0082C0.948175 10.0082 1.0571 9.98646 1.15865 9.94415C1.2602 9.90185 1.35236 9.83985 1.42983 9.76174L5.00483 6.17841L8.57983 9.76174C8.6573 9.83985 8.74947 9.90185 8.85102 9.94415C8.95257 9.98646 9.06149 10.0082 9.1715 10.0082C9.28151 10.0082 9.39043 9.98646 9.49198 9.94415C9.59353 9.90185 9.6857 9.83985 9.76317 9.76174C9.84127 9.68427 9.90327 9.59211 9.94558 9.49056C9.98788 9.38901 10.0097 9.28009 10.0097 9.17008C10.0097 9.06007 9.98788 8.95115 9.94558 8.8496C9.90327 8.74805 9.84127 8.65588 9.76317 8.57841L6.17983 5.00341Z"
          fill="#7E7E80"
        />
      </svg>
    </div>
    {{ computedBudget ?? 'Бюджет' }}
  </div>
</template>

<script>
export default {
  props: {
    budget: {
      type: Number,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  emits: ['OnWipeBudget'],
  computed: {
    computedBudget () {
      if (this.budget > 0) {
        const fractional = this.budget % 100
        const value = Math.floor(this.budget / 100)
        let valString = ''
        let valueString = value.toString()
        while (valueString) {
          valString = valueString.slice(-3) + ' ' + valString
          valueString = valueString.slice(0, -3)
        }
        valString = valString.trim()
        if (fractional > 0) {
          const frString = (fractional < 10 ? '0' : '') + fractional
          return `${valString}.${frString}`
        }
        return valString
      }
      return 'Бюджет'
    }
  }
}
</script>
