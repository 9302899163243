<template>
  <div
    class="flex items-center text-[14px] break-words whitespace-pre-line text-[#7E7E80]"
  >
    <svg
      class="mr-1.5"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59059 4.46916L7.39143 4.4258L7.16897 10.4265L8.36813 10.4699L8.59059 4.46916Z"
        fill="#7E7E80"
      />
      <path
        d="M11.52 1.99639H8.64V0.969233C8.64 0.495159 8.24 0.100098 7.76 0.100098H4.4C3.92 0.100098 3.52 0.495159 3.52 0.969233V1.99639H0.56C0.24 1.99639 0 2.23343 0 2.54948C0 2.86553 0.24 3.10257 0.56 3.10257H1.04L1.84 12.347C1.84 12.6631 2.08 12.9001 2.4 12.9001H9.6C9.92 12.9001 10.16 12.6631 10.16 12.347L10.96 3.10257H11.44C11.76 3.10257 12 2.86553 12 2.54948C12 2.23343 11.84 1.99639 11.52 1.99639ZM4.64 1.28528H7.44V1.99639H4.64V1.28528ZM9.12 11.7149H2.96L2.16 3.10257H9.76L9.12 11.7149Z"
        fill="#7E7E80"
      />
      <path
        d="M6.64 4.44578H5.44V10.4507H6.64V4.44578Z"
        fill="#7E7E80"
      />
      <path
        d="M4.88358 10.4515L4.66105 4.45076L3.46189 4.49413L3.68442 10.4949L4.88358 10.4515Z"
        fill="#7E7E80"
      />
    </svg>
    Сообщение удалено
  </div>
</template>
