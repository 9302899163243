export const NAVIGATOR_REQUEST = 'NAVIGATOR_REQUEST'
export const NAVIGATOR_SUCCESS = 'NAVIGATOR_SUCCESS'
export const NAVIGATOR_ERROR = 'NAVIGATOR_ERROR'
export const PATCH_SETTINGS = 'PATCH_SETTINGS'
export const PATCH_SETTINGS_SUCCESS = 'PATCH_SETTINGS_SUCCESS'
export const RESET_STATE_NAVIGATOR = 'RESET_STATE_NAVIGATOR '
export const UPDATE_SETTINGS_VALUE = 'UPDATE_SETTINGS_VALUE'

export const NAVIGATOR_PUSH_PROJECT = 'NAVIGATOR_PUSH_PROJECT'
export const NAVIGATOR_PUSH_COMMON_PROJECT = 'NAVIGATOR_PUSH_COMMON_PROJECT'
export const NAVIGATOR_REMOVE_PROJECT = 'NAVIGATOR_REMOVE_PROJECT'

export const NAVIGATOR_PUSH_TAG = 'NAVIGATOR_PUSH_TAG'
export const NAVIGATOR_REMOVE_TAG = 'NAVIGATOR_REMOVE_TAG'

export const NAVIGATOR_PUSH_COLOR = 'NAVIGATOR_PUSH_COLOR'
export const NAVIGATOR_UPDATE_REGLAMENT = 'NAVIGATOR_UPDATE_REGLAMENT'
export const NAVIGATOR_REMOVE_COLOR = 'NAVIGATOR_REMOVE_COLOR'

export const NAVIGATOR_PUSH_EMPLOYEE = 'NAVIGATOR_PUSH_EMPLOYEE'
export const NAVIGATOR_REMOVE_EMPLOYEE = 'NAVIGATOR_REMOVE_EMPLOYEE'
export const NAVIGATOR_UPDATE_EMPLOYEE = 'NAVIGATOR_UPDATE_EMPLOYEE'
export const NAVIGATOR_CHANGE_EMPLOYEE_DEPARTMENT =
  'NAVIGATOR_CHANGE_EMPLOYEE_DEPARTMENT'

export const NAVIGATOR_UPDATE_ASSIGNMENTS = 'NAVIGATOR_UPDATE_ASSIGNMENTS'

export const NAVIGATOR_PUSH_DEPARTAMENT = 'NAVIGATOR_PUSH_DEPARTAMENT'
export const NAVIGATOR_REMOVE_DEPARTAMENT = 'NAVIGATOR_REMOVE_DEPARTAMENT'
export const NAVIGATOR_UPDATE_DEPARTMENT = 'NAVIGATOR_UPDATE_DEPARTMENT'

export const NAVIGATOR_PUSH_BOARD = 'NAVIGATOR_PUSH_BOARD'
export const NAVIGATOR_PUSH_COMMON_BOARD = 'NAVIGATOR_PUSH_COMMON_BOARD'
export const NAVIGATOR_REMOVE_BOARD = 'NAVIGATOR_REMOVE_BOARD'

export const NAVIGATOR_PUSH_REGLAMENT = 'NAVIGATOR_PUSH_REGLAMENT'
export const NAVIGATOR_REMOVE_REGLAMENT = 'NAVIGATOR_REMOVE_REGLAMENT'

export const NAVIGATOR_PUSH_NOTIFICATION_TASKS = 'NAVIGATOR_PUSH_NOTIFICATION_TASKS'
