export const MESSAGES_REQUEST = 'MESSAGES_REQUEST'
export const INSPECTOR_MESSAGES_REQUEST = 'INSPECTOR_MESSAGES_REQUEST'
export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST'
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS'
export const INSPECTOR_MESSAGES_SUCCESS = 'INSPECTOR_MESSAGES_SUCCESS'
export const MESSAGES_ERROR = 'MESSAGES_ERROR'
export const REFRESH_MESSAGES = 'REFRESH_MESSAGES'
export const REFRESH_CHAT_MESSAGES = 'REFRESH_CHAT_MESSAGES'
export const REFRESH_INSPECTOR_MESSAGES = 'REFRESH_INSPECTOR_MESSAGES'
export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST'

export const CHANGE_MESSAGE = 'TASK_CHANGE_MESSAGE'
export const REMOVE_MESSAGE_LOCALLY = 'TASK_REMOVE_MESSAGE_LOCALLY'
