export const PUSH_EMPLOYEE_IF_NEED = 'PUSH_EMPLOYEE_IF_NEED'
export const PUSH_EMPLOYEE = 'PUSH_EMPLOYEE'
export const PUSH_EMPLOYEE_BY_EMAIL = 'PUSH_EMPLOYEE_BY_EMAIL'
export const SELECT_EMPLOYEE = 'SELECT_EMPLOYEE'

export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST'
export const REMOVE_EMPLOYEE_REQUEST = 'REMOVE_EMPLOYEE_REQUEST'
export const CHANGE_EMPLOYEE_NAME = 'CHANGE_EMPLOYEE_NAME'
export const CHANGE_EMPLOYEE_DEP = 'CHANGE_EMPLOYEE_DEP'
export const RESET_EMPLOYEE_STATE = 'RESET_EMPLOYEE_STATE'

export const SET_ADMIN_STATUS_REQUEST = 'SET_ADMIN_STATUS_REQUEST'
export const REMOVE_ADMIN_STATUS_REQUEST = 'REMOVE_ADMIN_STATUS_REQUEST'
