<template>
  <div
    class="flex flex-col justify-center items-center py-6 px-5 bg-white rounded-lg max-w-full"
  >
    <p class="text-[25px] font-bold mb-[10px]">
      Обновите тариф
    </p>
    <p class="font-bold">
      Поиск - функция тарифа "Премиум"
    </p>
    <p>Функция поиска не доступна в Вашем текущем тарифном плане.</p>
    <a
      href="https://www.leadertask.ru/alpha"
      class="mt-5 w-[238px] flex justify-center font-[500] items-center text-center cursor-pointer bg-[#F2B679] text-[#2E2E2E] text-lg px-10 py-1 rounded-md hover:bg-slate-200 hover:text-[#422b14]"
    >
      Подключить сейчас
    </a>
  </div>
</template>
