
/**
 * @enum {number}
 * @readonly
 */
export const TASK_STATUS = {
  NOT_BEGIN: 0,
  TASK_COMPLETED: 1,
  LINK_TYPE: 2,
  NOTE: 3,
  TASK_IN_WORK: 4,
  TASK_READY: 5,
  TASK_PAUSED: 6,
  TASK_CANCELLED: 7,
  TASK_REJECTED: 8,
  TASK_REFINE: 9
}

export const CARD_STAGE = {
  ARCHIVE_SUCCESS: 'f98d6979-70ad-4dd5-b3f8-8cd95cb46c67',
  ARCHIVE_REJECT: 'e70af5e2-6108-4c02-9a7d-f4efee78d28c'
}
