<template>
  <div
    class="animate-pulse p-5 bg-white rounded-xl w-[30%] shrink-0 self-start"
  >
    <div class="bg-[#EAEAEA] rounded-[10px] grow h-[37px] mb-[12px]" />
    <div>
      <div class="flex items-center mb-[12px]">
        <div class="bg-[#ff9123] h-[22px] w-[22px] rounded-md mr-[10px]" />
        <div class="bg-[#EAEAEA] mx-auto rounded-[10px] grow h-[37px]" />
      </div>
      <div class="flex items-center mb-[12px]">
        <div class="bg-[#ff9123] h-[22px] w-[22px] rounded-md mr-[10px]" />
        <div class="bg-[#EAEAEA] mx-auto rounded-[10px] grow h-[37px]" />
      </div>
      <div class="flex items-center mb-[12px]">
        <div class="bg-[#ff9123] h-[22px] w-[22px] rounded-md mr-[10px]" />
        <div class="bg-[#EAEAEA] mx-auto rounded-[10px] grow h-[37px]" />
      </div>
      <div class="flex items-center mb-[12px]">
        <div class="bg-[#ff9123] h-[22px] w-[22px] rounded-md mr-[10px]" />
        <div class="bg-[#EAEAEA] mx-auto rounded-[10px] grow h-[37px]" />
      </div>
    </div>
    <div class="bg-[#EAEAEA] mx-auto rounded-[10px] w-full h-[37px] mb-[12px]" />
    <div class="bg-[#EAEAEA] mx-auto rounded-[10px] w-full h-[37px] mb-[12px]" />
    <div class="bg-[#ff9123] mx-auto rounded-[10px] w-full h-[40px] mb-[12px]" />
  </div>
</template>
