export const MESSAGES_REQUEST = 'CLIENT_MESSAGES_REQUEST'
export const CREATE_MESSAGE_REQUEST = 'CLIENT_CREATE_MESSAGE_REQUEST'
export const MESSAGES_SUCCESS = 'CLIENT_MESSAGES_SUCCESS'
export const MESSAGES_ERROR = 'CLIENT_MESSAGES_ERROR'
export const FILL_MESSAGES = 'FILL_MESSAGES'
export const REFRESH_MESSAGES = 'CLIENT_REFRESH_MESSAGES'
export const DELETE_MESSAGE_REQUEST = 'CLIENT_DELETE_MESSAGE_REQUEST'

export const FILES_REQUEST = 'CLIENT_FILES_REQUEST'
export const FILE_REQUEST = 'CLIENT_FILE_REQUEST'
export const FILES_SUCCESS = 'CLIENT_FILES_SUCCESS'
export const CREATE_FILES_REQUEST = 'CLIENT_CREATE_FILES_REQUEST'
export const CREATE_FILE_REQUEST = 'CLIENT_CREATE_FILE_REQUEST'
export const FILES_ERROR = 'CLIENT_FILES_ERROR'
export const REFRESH_FILES = 'CLIENT_REFRESH_FILES'
export const DELETE_FILE_REQUEST = 'CLIENT_DELETE_FILE_REQUEST'

export const CHANGE_MESSAGE = 'CLIENT_CHANGE_MESSAGE'

export const REMOVE_MESSAGE_LOCALLY = 'CLIENT_REMOVE_MESSAGE_LOCALLY'
export const ADD_MESSAGE_LOCALLY = 'CLIENT_ADD_MESSAGE_LOCALLY'

export const FETCH_FILES_AND_MESSAGES = 'CLIENT_FETCH_FILES_AND_MESSAGES'
export const MERGE_FILES_AND_MESSAGES = 'CLIENT_MERGE_FILES_AND_MESSAGES'
