<template>
  <div class="animate-pulse flex flex-col">
    <div class="flex mb-[20px] flex-row w-full text-dark px-[16px] mt-[22px] h-[32px] items-center">
      <hr class="block h-[32px] bg-neutral-300  mt-1 mb-1 rounded-[10px] w-[32px] mr-2">
      <hr class="block h-[15px] bg-neutral-200  mt-1 mb-1 rounded-[10px] w-[50%]">
    </div>

    <div class="flex flex-row w-full text-dark px-[16px] mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[40%]">
    </div>
    <div class="flex w-full text-dark px-[16px] items-center mt-[5px]">
      <div class="flex h-[40px] bg-white  mt-1 items-center rounded-[10px] w-[100%]">
        <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
        <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[43%]">
      </div>
    </div>
    <div class="flex flex-row w-full text-dark px-[16px] mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[50%]">
    </div>
    <div class="flex flex-row w-full text-dark px-[16px] mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[35%]">
    </div>
    <div class="flex flex-row w-full text-dark px-[16px] mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[25%]">
    </div>

    <div class="flex flex-row w-full text-dark px-[16px] mt-[15px] items-center justify-center">
      <hr class="block h-[34px] bg-[#424242] mt-1 rounded-[10px] w-[90%]">
    </div>
  </div>
</template>
