export default {
  width: 16,
  height: 17,
  viewBox: '0 0 16 17',
  path: [
    'M7.6 6.1101C7.46 6.7701 7.52 7.4601 7.79 8.0901C8.05 8.7201 8.5 9.2501 9.08 9.6301C9.66 10.0101 10.34 10.2201 11.04 10.2201H11.06C12.01 10.2201 12.9 9.8701 13.57 9.2201C14.25 8.5701 14.62 7.7101 14.63 6.7901C14.63 6.1101 14.42 5.4501 14.03 4.8901C13.64 4.3301 13.09 3.8901 12.44 3.6301C12 3.4601 11.55 3.3701 11.07 3.3701C10.84 3.3701 10.62 3.3901 10.39 3.4301C9.71 3.5601 9.08 3.8901 8.58 4.3601C8.08 4.8401 7.74 5.4401 7.6 6.1101ZM11.47 4.7801C11.88 4.8601 12.26 5.0501 12.57 5.3401C12.88 5.6301 13.08 6.0001 13.17 6.3901C13.2 6.5201 13.21 6.6601 13.22 6.8001C13.22 7.2101 13.1 7.6001 12.86 7.9401C12.63 8.2801 12.3 8.5401 11.91 8.7001C11.65 8.8101 11.37 8.8601 11.08 8.8601C10.94 8.8601 10.81 8.8501 10.67 8.8201C10.25 8.7401 9.88 8.5501 9.57 8.2601C9.27 7.9701 9.06 7.6101 8.98 7.2101C8.89 6.8101 8.93 6.4001 9.09 6.0201C9.25 5.6401 9.51 5.3201 9.86 5.0901C10.21 4.8601 10.62 4.7301 11.04 4.7301L11.05 4.6301V4.7301C11.19 4.7401 11.33 4.7501 11.47 4.7801Z',
    'M7.07 8.0201L6.99 7.8701C6.79 7.5101 6.64 7.1301 6.55 6.7301L6.53 6.6701H6.55L6.52 6.5401C6.47 6.2901 6.45 6.0401 6.44 5.8001V5.3201L6.35 5.3101C5.9 5.2501 5.5 5.0301 5.21 4.6901C4.93 4.3501 4.8 3.9201 4.84 3.4801C4.88 3.0401 5.09 2.6501 5.43 2.3601C5.75 2.0901 6.16 1.9401 6.67 1.9401H6.68C7.15 1.9401 7.6 2.1101 7.94 2.4301L8 2.4901L8.07 2.4401C8.38 2.2001 8.72 1.9901 9.08 1.8201L9.2 1.7601L9.1 1.6601C8.68 1.1901 8.12 0.860098 7.49 0.700098C7.23 0.630098 6.96 0.600098 6.69 0.600098C6.31 0.600098 5.94 0.660098 5.59 0.790098C4.98 1.0001 4.46 1.3801 4.08 1.8901C3.7 2.4101 3.49 3.0101 3.48 3.6401C3.49 4.4101 3.8 5.1401 4.35 5.7001C4.88 6.2401 5.59 6.5801 6.36 6.6501C4.71 6.6901 3.06 7.1001 1.59 7.8301C1.41 7.9201 1.26 8.0601 1.16 8.2201C1.06 8.3901 1 8.5801 1 8.7801V10.5901C1 10.7701 1.07 10.9501 1.21 11.0801C1.34 11.2101 1.52 11.2801 1.71 11.2801C1.89 11.2801 2.08 11.2101 2.21 11.0801C2.34 10.9501 2.42 10.7801 2.42 10.5901V8.9601C3.73 8.3401 5.18 8.0201 6.63 8.0201H7.07Z',
    'M14.52 15.4801H6.04V13.1501C7.6 12.4101 9.34 12.0101 11.08 12.0101C11.79 12.0101 12.5 12.0701 13.19 12.2001L13.31 12.2201L13.25 10.8201L13.17 10.8101C12.46 10.6901 11.74 10.6301 11.01 10.6301C9.01 10.6301 7.01 11.1001 5.23 11.9901C5.04 12.0901 4.89 12.2401 4.78 12.4201C4.68 12.6001 4.63 12.8101 4.63 13.0101V16.0201C4.63 16.2001 4.7 16.3801 4.84 16.5101C4.86 16.5301 4.88 16.5301 4.89 16.5501C5.02 16.7001 5.2 16.8001 5.42 16.8001H14.53C14.9 16.8001 15.2 16.5101 15.2 16.1401C15.2 15.7701 14.9 15.4801 14.52 15.4801Z'
  ]
}
