<template>
  <div class="my-[4px] mx-[10px] flex h-px bg-[#dddddd]" />
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
