<template>
  <div class="flex flex-col justify-center items-center py-6 px-5 bg-white rounded-xl max-w-full">
    <h1 class="text-5xl text-gray-600 font-bold mb-5">
      Карма - функция тарифа Бизнес+
    </h1>
    <h3 class="text-2xl">
      Узнайте свою личную продуктивность и продуктивность команды по выполненным поручениям в Лидертаск.
    </h3>

    <a
      href="https://www.leadertask.ru/alpha"
      class="w-xl-1/6 mt-[35px] w-[238px] h-[40px] cursor-pointer bg-[#F2B679] text-[#2E2E2E] text-[15px] px-10 rounded-md hover:bg-slate-200 font-medium text-center pt-[6px]"
    >
      Купить
    </a>
  </div>
</template>
