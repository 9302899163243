export const FILES_REQUEST = 'FILES_REQUEST'
export const FILES_SUCCESS = 'FILES_SUCCESS'
export const CREATE_FILES_REQUEST = 'CREATE_FILES_REQUEST'
export const CREATE_FILE_REQUEST = 'CREATE_FILE_REQUEST'
export const FILES_ERROR = 'FILES_ERROR'
export const REFRESH_FILES = 'REFRESH_FILES'
export const GET_FILE = 'GET_FILE'
export const FILE_SUCCESS = 'FILE_SUCCESS'
export const MERGE_FILES_WITH_MESSAGES = 'MERGE_FILES_WITH_MESSAGES'
export const TOGGLE_UPLOAD_STATUS = 'TOGGLE_UPLOAD_STATUS'
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'
