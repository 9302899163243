export default {
  width: 19,
  height: 18,
  viewBox: '0 0 19 18',
  path: [
    'M0 1C0 0.447715 0.447715 0 1 0H4C4.55228 0 5 0.447715 5 1V3C5 3.55228 4.55228 4 4 4H1C0.447715 4 0 3.55228 0 3V1Z',
    'M7 1C7 0.447715 7.44772 0 8 0H11C11.5523 0 12 0.447715 12 1V3C12 3.55228 11.5523 4 11 4H8C7.44772 4 7 3.55228 7 3V1Z',
    'M14 1C14 0.447715 14.4477 0 15 0H18C18.5523 0 19 0.447715 19 1V3C19 3.55228 18.5523 4 18 4H15C14.4477 4 14 3.55228 14 3V1Z',
    'M0 8C0 7.44772 0.447715 7 1 7H4C4.55228 7 5 7.44772 5 8V10C5 10.5523 4.55228 11 4 11H1C0.447715 11 0 10.5523 0 10V8Z',
    'M7 8C7 7.44772 7.44772 7 8 7H11C11.5523 7 12 7.44772 12 8V10C12 10.5523 11.5523 11 11 11H8C7.44772 11 7 10.5523 7 10V8Z',
    'M14 8C14 7.44772 14.4477 7 15 7H18C18.5523 7 19 7.44772 19 8V10C19 10.5523 18.5523 11 18 11H15C14.4477 11 14 10.5523 14 10V8Z',
    'M0 15C0 14.4477 0.447715 14 1 14H4C4.55228 14 5 14.4477 5 15V17C5 17.5523 4.55228 18 4 18H1C0.447715 18 0 17.5523 0 17V15Z',
    'M7 15C7 14.4477 7.44772 14 8 14H11C11.5523 14 12 14.4477 12 15V17C12 17.5523 11.5523 18 11 18H8C7.44772 18 7 17.5523 7 17V15Z',
    'M14 15C14 14.4477 14.4477 14 15 14H18C18.5523 14 19 14.4477 19 15V17C19 17.5523 18.5523 18 18 18H15C14.4477 18 14 17.5523 14 17V15Z'
  ]
}
