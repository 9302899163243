<template>
  <div class="flex-none flex items-center justify-center h-[30px] w-[30px] bg-[#F4F5F7] rounded-[6px] cursor-pointer">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16C8 14.8954 8.89543 14 10 14Z"
        fill="#7F7F80"
      />
      <path
        d="M10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8Z"
        fill="#7F7F80"
      />
      <path
        d="M10 2C11.1046 2 12 2.89543 12 4C12 5.10457 11.1046 6 10 6C8.89543 6 8 5.10457 8 4C8 2.89543 8.89543 2 10 2Z"
        fill="#7F7F80"
      />
    </svg>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
