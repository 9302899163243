<template>
  <div class="animate-pulse grid">
    <div class="flex flex-row w-full text-dark items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[40%]">
    </div>
    <div class="flex w-full text-dark items-center mt-[5px]">
      <div class="flex py-[5px] bg-white items-center rounded-[10px] w-[100%]">
        <hr class="block h-[32px] bg-[#EAEAEA] ml-1 rounded-[10px] w-[32px] mr-[10px]">
        <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[43%]">
      </div>
    </div>
    <div class="flex flex-row w-full text-dark mt-[1px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[50%]">
    </div>
    <div class="flex flex-row w-full text-dark mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[35%]">
    </div>
    <div class="flex flex-row w-full text-dark mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[25%]">
    </div>
    <div class="flex flex-row w-full text-dark mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[60%]">
    </div>
    <div class="flex flex-row w-full text-dark mt-[5px] items-center justify-start">
      <hr class="block h-[32px] bg-[#EAEAEA] ml-1 mt-1 rounded-[10px] w-[32px] mr-[10px]">
      <hr class="block h-[16px] bg-[#EAEAEA] mt-1 rounded-[10px] w-[50%]">
    </div>
  </div>
</template>
