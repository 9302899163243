<template>
  <div class="mt-[34px]">
    <p class="text-base font-medium text-[#4C4C4D]">
      Владелец лицензии
    </p>
    <div class="flex mt-[15px]">
      <span class="relative mr-1.5">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8468 2.10515C14.7697 2.29292 15.624 2.72825 16.3177 3.36433C17.0114 4.00041 17.5183 4.81318 17.7839 5.71528C18.0299 6.54241 18.0666 7.41752 17.8903 8.26293C17.6355 9.42119 16.9944 10.4588 16.0719 11.206C15.1494 11.9531 14.0001 12.3656 12.8121 12.3757C12.3108 12.3757 11.8118 12.3038 11.3323 12.1587L10.4167 13.2303L9.98292 13.4302H8.86703V15.1439L8.29478 15.7151H6.57802V17.4288L6.00577 18H2.57225L2 17.4288V14.7932L2.1671 14.3899L7.85987 8.70735C7.69831 8.18791 7.62103 7.64602 7.63096 7.10221C7.6414 6.34138 7.81984 5.59222 8.15358 4.90812C8.48733 4.22403 8.96814 3.62185 9.56176 3.14451C10.1554 2.66718 10.8472 2.32644 11.5879 2.14659C12.3285 1.96673 13.0998 1.95219 13.8468 2.10401V2.10515ZM15.3552 10.3308C16.0742 9.74961 16.5742 8.94199 16.7733 8.04016L16.7778 8.04587C16.9197 7.38645 16.8941 6.70212 16.7036 6.05505C16.5131 5.40798 16.1636 4.81868 15.687 4.3407C15.2103 3.86273 14.6215 3.51122 13.9741 3.31813C13.3268 3.12503 12.6413 3.09647 11.9801 3.23503C11.0883 3.43261 10.2883 3.92235 9.70758 4.62625C9.12684 5.33016 8.79873 6.20777 8.77547 7.11935C8.76402 7.6403 8.85559 8.15669 9.04901 8.6388L8.92311 9.25915L3.14451 15.0296V16.8576H5.43352V15.1439L6.00577 14.5727H7.72253V12.859L8.29478 12.2878H9.71969L10.7223 11.127L11.3643 10.9671C11.8272 11.1476 12.3198 11.2399 12.8167 11.239C13.7415 11.2319 14.6365 10.9117 15.3552 10.3308ZM14.3961 7.2096C14.4864 7.08513 14.5505 6.94377 14.5848 6.79397C14.619 6.64418 14.6226 6.48903 14.5954 6.33781C14.5682 6.18658 14.5107 6.04239 14.4264 5.91386C14.342 5.78533 14.2326 5.67511 14.1046 5.58977C13.9766 5.50444 13.8327 5.44576 13.6814 5.41723C13.5302 5.38871 13.3747 5.39093 13.2244 5.42376C13.074 5.4566 12.9318 5.51937 12.8063 5.60833C12.6808 5.69729 12.5746 5.8106 12.494 5.94148C12.3389 6.19326 12.2876 6.49524 12.3509 6.78397C12.4142 7.0727 12.5871 7.32571 12.8333 7.48986C13.0795 7.65401 13.3799 7.71652 13.6713 7.66426C13.9628 7.612 14.2225 7.44903 14.3961 7.2096Z"
            fill="#606061"
          />
        </svg>
      </span>
      <span class="text-sm text-[#606061]">
        {{ user?.owner_title }}
      </span>
    </div>
  </div>
  <div class="mt-[34px]">
    <p class="text-base font-medium text-[#4C4C4D]">
      Почта владельца лицензии
    </p>
    <div class="flex mt-[17px]">
      <span class="relative mr-1.5">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9286 5H3.07143C2.78727 5 2.51475 5.10536 2.31381 5.29289C2.11288 5.48043 2 5.73478 2 6V14C2 14.2652 2.11288 14.5196 2.31381 14.7071C2.51475 14.8946 2.78727 15 3.07143 15H15.9286C16.2127 15 16.4853 14.8946 16.6862 14.7071C16.8871 14.5196 17 14.2652 17 14V6C17 5.73478 16.8871 5.48043 16.6862 5.29289C16.4853 5.10536 16.2127 5 15.9286 5ZM14.75 6L9.5 9.39L4.25 6H14.75ZM3.07143 14V6.455L9.19464 10.41C9.28432 10.4681 9.39086 10.4992 9.5 10.4992C9.60914 10.4992 9.71568 10.4681 9.80536 10.41L15.9286 6.455V14H3.07143Z"
            fill="#606061"
          />
        </svg>
      </span>
      <span class="text-sm text-[#606061]">
        {{ user?.owner_email }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
