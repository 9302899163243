export default {
  width: 22,
  height: 22,
  viewBox: '0 0 22 22',
  path: [
    'M11 6.875C11.7594 6.875 12.375 6.25939 12.375 5.5C12.375 4.74061 11.7594 4.125 11 4.125C10.2406 4.125 9.625 4.74061 9.625 5.5C9.625 6.25939 10.2406 6.875 11 6.875Z',
    'M11 12.375C11.7594 12.375 12.375 11.7594 12.375 11C12.375 10.2406 11.7594 9.625 11 9.625C10.2406 9.625 9.625 10.2406 9.625 11C9.625 11.7594 10.2406 12.375 11 12.375Z',
    'M11 17.875C11.7594 17.875 12.375 17.2594 12.375 16.5C12.375 15.7406 11.7594 15.125 11 15.125C10.2406 15.125 9.625 15.7406 9.625 16.5C9.625 17.2594 10.2406 17.875 11 17.875Z'
  ]
}
