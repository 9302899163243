<template>
  <div
    class="flex flex-col justify-center items-center py-6 px-5 bg-white rounded-lg max-w-full"
  >
    <p class="text-[25px] font-bold mb-[10px]">
      Обновите тариф
    </p>
    <p class="font-bold">
      Очередь - функция тарифа "Бизнес+"
    </p>
    <p>С помощью данного раздела вы сможете не отвлекаться на посторонние дела и сфокусироваться на одной задаче!</p>
    <a
      href="https://www.leadertask.ru/alpha"
      class="w-xl-1/6 mt-[35px] w-[238px] h-[40px] cursor-pointer bg-[#F2B679] text-[#2E2E2E] text-[14px] px-10 rounded-md hover:bg-slate-200 font-medium text-center pt-[6px]"
    >
      Подключить сейчас
    </a>
  </div>
</template>
